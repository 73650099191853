import { useTimer } from "react-timer-hook";
import classes from "./CheckoutCountDown.module.css";
import { Row, Col } from "react-bootstrap";

const EventStartsInTimmer = ({ startTimeStamp }) => {
  const expiryTimestamp = new Date(startTimeStamp);
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds());

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  return (
    <div>
      <Row className="">
        <Col xs={12}>
          <div className={classes.countDownContainer}>
            <div className={classes.countDownElementContainer}>
              <div className={classes.countDownElement}>
                <p className={classes.countDownText}>
                  {days < 10 ? `0${days}` : days}
                </p>
              </div>
              <p className={classes.countDownElementTag}>days</p>
            </div>
            <p className={classes.elementDivider}>:</p>
            <div className={classes.countDownElementContainer}>
              <div className={classes.countDownElement}>
                <p className={classes.countDownText}>
                  {hours < 10 ? `0${hours}` : hours}
                </p>
              </div>
              <p className={classes.countDownElementTag}>hours</p>
            </div>
            <p className={classes.elementDivider}>:</p>
            <div className={classes.countDownElementContainer}>
              <div className={classes.countDownElement}>
                <p className={classes.countDownText}>
                  {minutes < 10 ? `0${minutes}` : minutes}
                </p>
              </div>
              <p className={classes.countDownElementTag}>minutes</p>
            </div>
            <p className={classes.elementDivider}>:</p>
            <div className={classes.countDownElementContainer}>
              <div className={classes.countDownElement}>
                <p className={classes.countDownText}>
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              </div>
              <p className={classes.countDownElementTag}>seconds</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventStartsInTimmer;
