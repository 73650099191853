import gooseImage from "../images/empty-results-image.png";
import classes from "./ZeroSearchResultsMessage.module.css";

const ZeroSearchResultsMessage = () => {
  return (
    <div className={classes.emptyMessageContainer}>
      <div className={classes.emptyImageContainer}>
        <img
          src={gooseImage}
          className={classes.emptyImage}
          alt="goose image"
        />
      </div>
      <h4 className={classes.emptyMessageTitle}>
        <span style={{ whiteSpace: "nowrap" }}>“ All</span> upcoming events will
        appear <span style={{ whiteSpace: "nowrap" }}>here. “</span>
      </h4>
    </div>
  );
};

export default ZeroSearchResultsMessage;
