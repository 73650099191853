import { useEffect, useState } from "react";
import axios from "axios";
import { defer, useLoaderData, useParams } from "react-router-dom";
import RequestProgressBar from "./RequestProgressBar";
import classes from "./CompleteOrderPage.module.css";
import { Col, Row, Container } from "react-bootstrap";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

export default function CompleteOrderPage() {
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Events | Complete Order`
  );
  let { order_id: orderId } = useParams();
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const loaderData = useLoaderData();

  const downloadTicket = async (order_id) => {
    try {
      const response = await axios.get(`/download-ticket/${order_id}/`, {
        responseType: "blob",
      });

      // Create a new Blob object using the response data of the file
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `ticket_${order_id}.pdf`;

      // Append to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the ticket:", error.response);
    }
  };

  useEffect(() => {
    const { orderDetails } = loaderData;

    orderDetails
      .then(({ response }) => {
        setStatus("ok");
        setMessage(response.data.message);

        if (!response.data?.downloaded_order_receipt) {
          downloadTicket(response.data?.order_id);
        }
      })
      .catch(({ error }) => {
        setStatus("faild");
        setMessage(
          "Could not complete the order. Please try again or contact support. Please use the order ID when contacting us."
        );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [loaderData]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
        hideBackground={false}
      />
      {status && (
        <section className={classes.completeOrderPageContainer}>
          <Container className="customContainer">
            <Row className="justify-content-center">
              <Col xs="auto">
                {status === "ok" ? (
                  <span
                    className={`material-symbols-outlined ${classes.icon} ${classes.successIcon}`}
                  >
                    check_circle
                  </span>
                ) : (
                  <span
                    class={`material-symbols-outlined ${classes.icon} ${classes.faildIcon}`}
                  >
                    cancel
                  </span>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className={classes.orderId}>ORDER ID : {orderId}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                {message && <h5 className={classes.message}>{message}</h5>}
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
}

const orderDetailsLoader =
  (sendRequest) =>
  ({ params: { order_id } }) => {
    const params = {};

    return sendRequest(
      "get",
      `/order/${order_id}/complete-order/`,
      params
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

export const orderLoader = (sendRequest) => async (args) => {
  const orderDetails = orderDetailsLoader(sendRequest)(args);

  return defer({
    orderDetails,
  });
};
