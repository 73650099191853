import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  useLoaderData,
  defer,
  useNavigation,
  useSubmit,
  useParams,
  useActionData,
  useNavigate,
} from "react-router-dom";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import classes from "./CheckInPage.module.css";
import { BeatLoader, ScaleLoader } from "react-spinners";
import CheckInTicketPackageDetails from "./CheckInTicketPackageDetails";
import PrimaryActionButton from "./PrimaryActionButton";
import displayToastErrors from "../utils/displayToastErrors";
import { toast } from "react-toastify";

export default function CheckInPage() {
  const loaderData = useLoaderData();
  const actionData = useActionData();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { ticket_token } = useParams();
  const submit = useSubmit();
  const [total, setTotal] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [clearCheckIn, setClearCheckIn] = useState(false);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [ticketPackageDetails, setTicketPackageDetails] = useState([]);
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Check In`);
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/scan-tickets");
    }
  }, []);

  useEffect(() => {
    const { ticketsData } = loaderData;

    ticketsData
      .then(({ response }) => {
        setTicketDetails(response.data);

        if (response.data.more_room_available) {
          const ticketPackages = response.data.ticket_packages.filter(
            (item) => {
              return item.quantity - item.attended_count > 0;
            }
          );
          setTicketPackageDetails(ticketPackages);
          setTicketStatus("valid");
        } else {
          setTicketStatus("already_checked_in");
          setTicketPackageDetails([]);
        }
      })
      .catch(({ error }) => {
        setTicketStatus("invalid");
        setErrorMessage(error?.response?.data?.message);
        setTicketDetails(null);
        setTicketPackageDetails([]);
      })
      .finally(() => {
        setIsLoaded(true);
      });
  }, [loaderData]);

  const onSubmit = (e) => {
    e.preventDefault();

    const inputs = Array.from(e.target.elements).filter(
      (input) => input.dataset.packageId
    );

    const ticket_packages = inputs
      .map((input) => ({
        package: parseInt(input.dataset.packageId),
        attended_count: parseInt(input.value),
      }))
      .filter((item) => item.attended_count > 0);

    submit(
      {
        ticket_packages: JSON.stringify(ticket_packages),
      },
      {
        method: "post",
        action: `/check-in/${ticket_token}`,
      }
    );
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(actionData?.response?.data?.message);

        setClearCheckIn((prev) => !prev);
        setTotal(0);
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message,
          "Faild to initiate the order!"
        );
      }
    }
  }, [actionData]);

  return (
    <section>
      <Container className={classes.customContainer}>
        {isLoaded ? (
          <Row>
            <Col xs={12}>
              <div className={classes.bottomScannerParent}>
                <div
                  className={classes.scanIconContainer}
                  onClick={() => navigate("/scan-tickets/?scanner=true")}
                >
                  <span
                    className={`material-symbols-outlined ${classes.scanIcon}`}
                  >
                    qr_code_scanner
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <div
                className={`${classes.ticketDetailsContainer} ${
                  ticketStatus === "valid"
                    ? classes.validSection
                    : ticketStatus === "invalid"
                    ? classes.invalidSection
                    : ticketStatus === "already_checked_in"
                    ? classes.alreadyCheckedInSection
                    : null
                }`}
              >
                <h3 className={classes.eventName}>
                  {ticketDetails?.event_name}
                </h3>
                {ticketStatus && (
                  <div className={classes.statusSection}>
                    {ticketStatus === "valid" ? (
                      <div className={classes.ticketStatusContainer}>
                        <span
                          className={`material-symbols-outlined ${classes.validIcon}`}
                        >
                          check_circle
                        </span>
                        <p className={classes.ticketStatusText}>valid ticket</p>
                      </div>
                    ) : ticketStatus === "invalid" ? (
                      <div className={classes.ticketStatusContainer}>
                        <span
                          className={`material-symbols-outlined ${classes.closeIcon}`}
                        >
                          close
                        </span>
                        <p className={classes.ticketStatusText}>
                          Invalid ticket
                        </p>
                      </div>
                    ) : ticketStatus === "already_checked_in" ? (
                      <div className={classes.ticketStatusContainer}>
                        <span
                          className={`material-symbols-outlined ${classes.closeIcon}`}
                        >
                          close
                        </span>
                        <p className={classes.ticketStatusText}>
                          Already checked in
                        </p>
                      </div>
                    ) : null}
                  </div>
                )}
                {errorMessage && (
                  <div className={classes.ticketDetailsItemContainer}>
                    <p className={classes.ticketDetailsItemTitle}>message :</p>
                    <p>{errorMessage}</p>
                  </div>
                )}
                {ticketDetails && (
                  <div>
                    <div className={classes.ticketDetailsItemContainer}>
                      <p className={classes.ticketDetailsItemTitle}>
                        reference number :
                      </p>
                      <p>{ticketDetails.reference_number}</p>
                    </div>
                    <div className={classes.ticketDetailsItemContainer}>
                      <p className={classes.ticketDetailsItemTitle}>
                        full name :
                      </p>
                      <p className={classes.ticketDetailsItemTitle}>
                        {ticketDetails.full_name}
                      </p>
                    </div>
                    <div className={classes.ticketDetailsItemContainer}>
                      <p className={classes.ticketDetailsItemTitle}>
                        contact number :
                      </p>
                      <p className={classes.ticketDetailsItemTitle}>
                        {ticketDetails.contact_number}
                      </p>
                    </div>
                    <div className={classes.ticketDetailsItemContainer}>
                      <p className={classes.ticketDetailsItemTitle}>
                        ordered tickets :
                      </p>
                      <p className={classes.ticketDetailsItemTitle}>
                        {ticketDetails.all_ticket_packages_text}
                      </p>
                    </div>
                    <div className={classes.ticketDetailsItemContainer}>
                      <p className={classes.ticketDetailsItemTitle}>
                        available tickets :
                      </p>
                      <p className={classes.ticketDetailsItemTitle}>
                        {ticketDetails.available_seats_text}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Col>
            {ticketPackageDetails.length > 0 && (
              <form onSubmit={onSubmit}>
                <Col xs={12}>
                  {ticketPackageDetails.map((ticketPackage) => (
                    <CheckInTicketPackageDetails
                      key={ticketPackage.id}
                      packageId={ticketPackage.id}
                      packageName={ticketPackage.title}
                      setTotal={setTotal}
                      clearCheckIn={clearCheckIn}
                      seatsAvailable={
                        ticketPackage.quantity - ticketPackage.attended_count
                      }
                    />
                  ))}
                </Col>
                <Col xs={12}>
                  <PrimaryActionButton
                    className={`${classes.checkInButton}`}
                    disabled={total === 0 ? true : false}
                    type="submit"
                    buttonText={
                      navigation.state === "idle" ? (
                        "check-in"
                      ) : (
                        <BeatLoader loading size={10} />
                      )
                    }
                  />
                </Col>
              </form>
            )}
          </Row>
        ) : (
          <Row>
            <Col xs={12}>
              <div className={classes.loaderContainer}>
                <ScaleLoader color={"#e6e6ed"} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
}

const ticketDetailsLoader =
  (sendRequest) =>
  ({ params: { ticket_token } }) => {
    const params = {};

    return sendRequest(
      "get",
      `/order/${ticket_token}/verify-ticket/`,
      params
    ).then((resp) => {
      if (resp.status === "error") {
        throw resp;
      } else {
        return resp;
      }
    });
  };

export const ticketLoader = (sendRequest) => async (args) => {
  const ticketsData = ticketDetailsLoader(sendRequest)(args);

  return defer({
    ticketsData,
  });
};

export const checkInAction =
  (sendRequest) =>
  async ({ request, params: { ticket_token } }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    const package_data = {
      ticket_packages: JSON.parse(data.ticket_packages),
    };

    return sendRequest("put", `/order/${ticket_token}/check-in/`, package_data);
  };
