import classes from "./TicketCountComponenet.module.css";

const CheckInTicketCountComponent = ({
  packageId,
  ticketCount,
  setTicketCount,
  seatsAvailable,
  setTotal,
}) => {
  const decreaseTicketCount = () => {
    if (ticketCount > 0) {
      setTicketCount((prevCount) => prevCount - 1);

      setTotal((prev) => prev - 1);
    }
  };

  const increaseTicketCount = () => {
    if (ticketCount < seatsAvailable) {
      setTicketCount((prevCount) => prevCount + 1);

      setTotal((prev) => prev + 1);
    }
  };

  return (
    <div className={`d-flex`}>
      <div className={classes.counterButton} onClick={decreaseTicketCount}>
        <span className={`material-symbols-outlined ${classes.buttonContent}`}>
          remove
        </span>
      </div>
      <div className={classes.countContainer}>
        <input type="hidden" value={ticketCount} data-package-id={packageId} />
        <span>
          {ticketCount === 0
            ? "00"
            : ticketCount < 10
            ? `0${ticketCount}`
            : ticketCount}
        </span>
      </div>
      <div className={classes.counterButton} onClick={increaseTicketCount}>
        <span className={`material-symbols-outlined ${classes.buttonContent}`}>
          add
        </span>
      </div>
    </div>
  );
};

export default CheckInTicketCountComponent;
