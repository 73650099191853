import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classes from "./AddPromoCodeModal.module.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import { BeatLoader } from "react-spinners";
import FormikInputField from "./FormikInputField";
import { toast } from "react-toastify";
import displayToastErrors from "../utils/displayToastErrors";
import { useParams, useSubmit, useActionData } from "react-router-dom";

const AddPromoCodeModal = (props) => {
  const { order_id: orderId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const actionData = useActionData();
  const submit = useSubmit();

  const initialValues = {
    promo_code: "",
  };

  //form validation rules
  const validationSchema = Yup.object({
    promo_code: Yup.string().required("This field is required!"),
  });

  const onSubmit = (values) => {
    setIsLoading(true);
    submit(
      { intent: "add-promo", data: JSON.stringify(values) },
      { method: "post", action: `/checkout/${orderId}` }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "add-promo") {
      if (actionData.status === "ok") {
        toast.success("Promo code applied successfully");
        props.onHide();
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message ||
            "Faild to add promo code"
        );
      }

      setIsLoading(false);
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classes.modalContainer}
    >
      <Modal.Body className={classes.modalBody}>
        <span
          className={`material-symbols-outlined ${classes.modalCloseButton}`}
          onClick={() => props.onHide()}
        >
          close
        </span>
        <Row>
          <Col>
            <h4 className={classes.modalTitle}>add promo code</h4>
          </Col>
        </Row>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <div className={classes.formRow}>
                <FormikInputField
                  label="Promo Code"
                  fieldName="promo_code"
                  inputType="text"
                  required
                />
              </div>
              <PrimaryActionButton
                className={classes.sendMessageButton}
                type="submit"
                buttonText={
                  isLoading ? <BeatLoader loading size={10} /> : "Apply"
                }
              />
            </Form>
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPromoCodeModal;
