import classes from "./HomePage.module.css";
import { Container, Row, Col } from "react-bootstrap";
import ticketImage from "../images/top-banner-ticket-image.png";
import PrimaryActionButton from "./PrimaryActionButton";
import HomepageBenefitItem from "./HomepageBenefitItem";
import quotes from "../images/quotes.png";
import clientImage from "../images/client-image.webp";
import founderImage from "../images/founder-image.webp";
import instaIcon from "../images/instagram-icon.png";
import youtubeIcon from "../images/youtube-icon.png";
import linkedinIcon from "../images/linkedin-icon.png";
import facebookIcon from "../images/facebook-icon.png";
import PricingValueItem from "./PricingValueItem";
import { Element } from "react-scroll";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import { useContext } from "react";
import { contactContext } from "../App";

const HomePage = () => {
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Manage your event bookings hassle free..`
  );

  const createAnEventForFreeAction = () => {
    setContactUsDefaultMessage(
      "Hi! I'm interested in using Eventgooze to sell tickets for my upcoming event. Could you please give me a call to discuss more about the process? Thank you very much!"
    );
    setContactModalShow(true);
  };

  return (
    <>
      <section className={classes.tobBannerSection}>
        <Container className="customContainer">
          <Row>
            <Col className="d-flex align-items-center order-md-first order-last">
              <div className={classes.topBannerContentContainer}>
                <h1 className={classes.topBannerTitle}>
                  Manage your event bookings hassle free...
                </h1>
                <p className={classes.topBannerDescription}>
                  Start selling your event tickets instantly with Eventgooze.
                  Contact us to list your event on Eventgooze for free.
                </p>
                <div className={classes.topBannerActionButtonContainer}>
                  <PrimaryActionButton
                    buttonText="create an event for free"
                    onClick={createAnEventForFreeAction}
                  />
                </div>
              </div>
            </Col>
            <Col
              md="auto"
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={ticketImage}
                alt="ticket-image"
                className={classes.topBannerImage}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className={classes.benefitsSection}>
        <Container className="customContainer">
          <div className={classes.headingSection}>
            <h3
              className={`${classes.sectionTitle} ${classes.benefitsSectionTitle}`}
            >
              Managing your event bookings shouldn’t be a headache anymore{" "}
            </h3>
            <h3
              className={`${classes.sectionTitle} ${classes.decoratedSectionTitle} ${classes.benefitsSectionTitle}`}
            >
              That’s why we created{" "}
              <span className={classes.eventgoozeDecorator}>
                {" "}
                <span className={classes.decoratorText}>event</span>goo
                <span className={classes.decoratorText}>z</span>e
              </span>
            </h3>
          </div>
          <div className={classes.benefitItemsContainer}>
            <Row className="gx-5">
              <HomepageBenefitItem
                number="1"
                title="Publish your event on eventgooze for free."
                content="Publishing your event on Eventgooze is completely free. Simply contact our team to start selling your event tickets instantly."
              />
              <HomepageBenefitItem
                number="2"
                title="Sell tickets online with no worries."
                content="Once your event is live on Eventgooze, we handle everything related to ticket sales. From managing customer inquiries to sending event updates, our dedicated team ensures a smooth process."
              />
            </Row>
            <Row className="gx-5">
              <HomepageBenefitItem
                number="3"
                title="Create unlimited promo codes for your event."
                content="Generate unlimited promo codes to boost your ticket sales. Share them with friends, influencers, and more to maximize your reach."
              />
              <HomepageBenefitItem
                number="4"
                title="Collect your payments securely."
                content="We use Payhere, Sri Lanka's most trusted payment gateway, supporting Helapay, Visa, MasterCard, and more. You’ll receive your ticket payments, minus fees, the next business day after the event."
              />
            </Row>
          </div>
        </Container>
      </section>
      <section className={classes.clientTestimonialSection}>
        <Container className="overflow-hidden customContainer">
          <h3
            className={`${classes.sectionTitle} ${classes.testimonialSectionTitle}`}
          >
            Let our clients speak
          </h3>
          <Row className="gx-5">
            <Col lg="auto" xs="12" className="d-flex justify-content-center">
              <div
                className={classes.clientImageContainer}
                style={{ backgroundImage: `url(${clientImage})` }}
              >
                <div className={classes.clientDetailsContainer}>
                  <h4 className={classes.clientName}>Ramesses Reezy</h4>
                  <div className={classes.clientSocialLinksContainer}>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.instagram.com/ramessesreezy/?hl=en"
                        target="blank"
                      >
                        <img src={instaIcon} alt="insta-icon" />
                      </a>
                    </div>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.youtube.com/channel/UCDV1MaVP0PIN2qwtse3sjAA"
                        target="blank"
                      >
                        <img src={youtubeIcon} alt="youtube-icon" />
                      </a>
                    </div>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://www.facebook.com/raemesiz/"
                        target="blank"
                      >
                        <img src={facebookIcon} alt="fb-icon" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="d-flex align-items-center">
              <Row>
                <Col xs="auto">
                  <img
                    className={classes.quoteSymbol}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
                <Col className={classes.clientReviewContentContainer}>
                  <p className={classes.clientReviewContent}>
                    I recently used Eventgooze to manage the online ticket sales
                    and check-in for my latest event{" "}
                    <span
                      className={classes.clientReviewContentHighlitedContent}
                    >
                      "Iconography Green Room"
                    </span>
                    , and I couldn't be more impressed. The platform was
                    incredibly user-friendly, and the team provided excellent
                    customer service throughout the entire process.
                    <br />
                    From start to finish, Eventgooze handled the online ticket
                    selling process seamlessly. Their system was easy to
                    navigate, and the checkout process was quick and efficient.
                    Additionally, the ability to create unlimited promo codes
                    was a huge bonus. It allowed me to run targeted marketing
                    campaigns and boost ticket sales.
                    <br />
                    On event day, the check-in process was smooth and
                    hassle-free. The Eventgooze staff were friendly and
                    efficient, ensuring a seamless experience for both attendees
                    and organizers.
                    <br />
                    Overall, I was extremely satisfied with Eventgooze's
                    services. They offered great value for the price, and their
                    platform made event management a breeze. I highly recommend
                    Eventgooze to anyone planning an event.
                    <br />
                  </p>
                </Col>
                <Col xs="auto" className="d-flex align-items-end">
                  <img
                    className={`${classes.quoteSymbol} ${classes.closingQuoteSymbol}`}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={classes.pricingSection}>
        <Element name="pricingSection">
          <Container className="customContainer">
            <h3 className={`${classes.sectionTitle}`}>
              How much do we charge ?
            </h3>
            <div className={classes.pricingSubTitleContainer}>
              <h5
                className={`${classes.sectionSubTitle} ${classes.pricingSubTitle}`}
              >
                Now you have the opportunity to experience our great service for{" "}
                <span
                  className={`${classes.eventgoozeDecorator} ${classes.pricingDecorator}`}
                >
                  {" "}
                  just <span className={classes.decoratorText}>4.99%</span>
                </span>{" "}
                per ticket.
              </h5>
              <h5 className={classes.sectionSubTitle}>For that price,</h5>
            </div>
            <div className={classes.pricingValuesContainerParent}>
              <div className={classes.pricingValuesContainer}>
                <PricingValueItem
                  content={
                    "You’ll be able to sell tickets through our platform effortlessly."
                  }
                />
                <PricingValueItem
                  content={
                    "Generate and share unlimited promo codes to boost sales."
                  }
                />
                <PricingValueItem
                  content={
                    "You have our well experienced check-in team to handle the complete check-in process at the event day without any extra fee."
                  }
                />
                <PricingValueItem
                  content={
                    "Your customers have the ability to pay for their tickets through Helapay, Visa, Master and all the other payment methods offered by Payhere, the most trusted payment gateway in Sri Lanka"
                  }
                />
              </div>
            </div>
            <div className={classes.pricingBottomSubTitleContainer}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "22px" }}
              >
                So... Why are you waiting?
              </h5>
              <h5 className={classes.sectionSubTitle}>
                We have your back till the event ends and even after
              </h5>
            </div>
            <div className="d-flex justify-content-center">
              <PrimaryActionButton
                buttonText="create an event for free"
                onClick={createAnEventForFreeAction}
              />
            </div>
          </Container>
        </Element>
      </section>
      <section className={classes.messageFromFounderSection}>
        <Container className="overflow-hidden customContainer">
          <h3
            className={`${classes.sectionTitle} ${classes.testimonialSectionTitle}`}
          >
            Message from our founder
          </h3>
          <Row className="gx-5">
            <Col className="d-flex align-items-center">
              <Row>
                <Col xs="auto">
                  <img
                    className={classes.quoteSymbol}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
                <Col className={classes.clientReviewContentContainer}>
                  <p className={classes.clientReviewContent}>
                    At Eventgooze, we believe that your event is more than just
                    a gathering; it's a reflection of your passion, dedication,
                    and vision. That's why we've built Eventgooze to be your
                    ultimate partner in event organization.
                    <br />
                    We value your trust and prioritize your needs above
                    everything else. Our platform is designed to simplify the
                    complexities of event management, allowing you to focus on
                    what truly matters: creating unforgettable experiences.
                    <br />
                    Our vision extends beyond event management. We aspire to
                    become a leading company in the industry, setting new
                    standards for excellence and innovation. By working with
                    Eventgooze, you're not just choosing a platform; you're
                    partnering with a team dedicated to making your events
                    extraordinary. <br />
                    We're confident that once you experience the Eventgooze
                    difference, you'll be eager to collaborate with us again.
                    Thank you for choosing Eventgooze. We're honored to be a
                    part of your journey. <br />
                  </p>
                </Col>
                <Col xs="auto" className="d-flex align-items-end">
                  <img
                    className={`${classes.quoteSymbol} ${classes.closingQuoteSymbol}`}
                    src={quotes}
                    alt="quotes"
                  />
                </Col>
              </Row>
            </Col>
            <Col
              lg="auto"
              xs="12"
              className="d-flex justify-content-center order-lg-last order-first"
            >
              <div
                className={classes.clientImageContainer}
                style={{ backgroundImage: `url(${founderImage})` }}
              >
                <div className={classes.clientDetailsContainer}>
                  <h4 className={classes.founderName}>Thilanka Wijesooriya</h4>
                  <div className={classes.clientSocialLinksContainer}>
                    <div className={classes.clientSocialIconContainer}>
                      <a
                        href="https://linkedin.com/in/thilanka-wijesooriya-b2ba771b3"
                        target="blank"
                      >
                        <img
                          src={linkedinIcon}
                          alt="linkedin-icon"
                          style={{ width: "22px" }}
                        />
                      </a>
                    </div>
                    <div className={classes.clientSocialIconContainer}>
                      <img src={facebookIcon} alt="fb-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HomePage;
