import classes from "./AllEventsPage.module.css";
import { Container, Row, Col } from "react-bootstrap";
import ZeroSearchResultsMessage from "./ZeroSearchResultsMessage";
import AllEventsSingleEventComponent from "./AllEventsSingleEventComponent";
import { useEffect, useState } from "react";
import { defer, useLoaderData } from "react-router-dom";
import displayToastErrors from "../utils/displayToastErrors";
import RequestProgressBar from "./RequestProgressBar";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

export default function AllEventsPage() {
  const [startProgressBar, setStartProgressBar] = useState(true);
  const [completeProgressBar, setCompleteProgressBar] = useState(false);
  const [eventsList, setEventsList] = useState(null);
  const loaderData = useLoaderData();
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Events`);

  useEffect(() => {
    const { eventsList } = loaderData;

    eventsList
      .then(({ response }) => {
        setEventsList(response.data);
      })
      .catch(({ error }) => {
        displayToastErrors(
          error?.response?.data?.message || "Faild to load events!"
        );
      })
      .finally(() => {
        setCompleteProgressBar(true);
      });
  }, [loaderData]);

  return (
    <>
      <RequestProgressBar
        continuousStart={startProgressBar}
        complete={completeProgressBar}
        hideBackground={false}
      />
      <div className={classes.AllEventsPageContainer}>
        <section className={classes.upcomingEventsSection}>
          <Container className="customContainer">
            <Row>
              <Col>
                <h1 className={classes.sectionTitle}>Upcoming Events</h1>
                <div className={classes.sectionTitleDivider}></div>
              </Col>
            </Row>
            {eventsList && (
              <div className={classes.eventsContainer}>
                {eventsList.length === 0 ? (
                  <ZeroSearchResultsMessage />
                ) : (
                  <Row className="gx-5">
                    {eventsList.map((event, key) => (
                      <Col
                        xl={3}
                        lg={4}
                        sm={6}
                        xs={12}
                        className="d-flex justify-content-center"
                        key={key}
                      >
                        <AllEventsSingleEventComponent
                          eventId={event.id}
                          eventThumbnail={event.image}
                          eventTitle={event.title}
                          eventStartDate={event.start_date_and_time}
                          eventSlug={event.event_slug}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </div>
            )}
          </Container>
        </section>
      </div>
    </>
  );
}

const eventsLoader = (sendRequest) => () => {
  const params = {};

  return sendRequest("get", `/events/`, params).then((resp) => {
    if (resp.status === "error") {
      throw resp;
    } else {
      return resp;
    }
  });
};

export const eventsListLoader = (sendRequest) => async (args) => {
  const eventsList = eventsLoader(sendRequest)(args);

  return defer({
    eventsList,
  });
};
