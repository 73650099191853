import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./NavigationBar.module.css";
import NavbarBusinessLogo from "./NavbarBusinessLogo";
import { contactContext } from "../App";
import { Link } from "react-scroll";

const NavigationBar = () => {
  const { contactModalShow, setContactModalShow, setContactUsDefaultMessage } =
    useContext(contactContext);
  const { pathname } = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);

  useEffect(() => {
    if (pathname === "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  }, [pathname]);

  const showContactModal = () => {
    setContactUsDefaultMessage("");
    setContactModalShow(true);
  };

  return (
    <section className={classes.navBarContainer}>
      <Container className="customContainer">
        <Row className="justify-content-md-between justify-content-center">
          <Col md="auto" xs={12} className="d-flex justify-content-center">
            <NavbarBusinessLogo />
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            <Row>
              <Col xs="auto">
                <div className={classes.navBarLinkContainerParent}>
                  <Row className="">
                    <Col xs="auto">
                      <NavLink
                        end
                        style={{ textDecoration: "none" }}
                        to={`/`}
                        className={({ isActive }) =>
                          isActive ? `${classes.activeTab}` : ""
                        }
                      >
                        <p className={classes.navLinkText}>home</p>
                      </NavLink>
                    </Col>
                    {isHomePage && (
                      <Col xs="auto">
                        <Link
                          end
                          style={{ textDecoration: "none" }}
                          to="pricingSection"
                        >
                          <p className={classes.navLinkText}>fees</p>
                        </Link>
                      </Col>
                    )}
                    <Col xs="auto">
                      <NavLink
                        end
                        style={{ textDecoration: "none" }}
                        to={`/events`}
                        className={({ isActive }) =>
                          isActive ? `${classes.activeTab}` : ""
                        }
                      >
                        <p className={classes.navLinkText}>all events</p>
                      </NavLink>
                    </Col>
                    <Col xs="auto">
                      <p
                        className={classes.navLinkText}
                        onClick={showContactModal}
                      >
                        contact us
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default NavigationBar;
