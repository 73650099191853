import classes from "./TicketMetaDataContainer.module.css";

const TicketMetaDataContainer = ({ label, icon, data }) => {
  return (
    <div className={classes.ticketMetaDataContainer}>
      <div className={`${classes.iconContainer}`}>
        <span className={`material-symbols-outlined`}>{icon}</span>
      </div>
      <div>
        <p className={classes.eventMetaData}>
          <span className={classes.eventMetaDataLabel}>{label} :</span>
          {data}
        </p>
      </div>
    </div>
  );
};

export default TicketMetaDataContainer;
