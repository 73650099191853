import { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import classes from "./QrReader.module.css";

// Styles
import "./QrReader.css";

// Qr Scanner
import QrScanner from "qr-scanner";
import QrFrame from "../images/qr-frame.svg";
import { useLocation, useNavigate } from "react-router-dom";

const QrReader = () => {
  // QR States
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [qrOn, setQrOn] = useState(true);
  const [turnOnScanner, setTurnOnScanner] = useState(false);
  const [scannedData, setScannedData] = useState("");

  // Handle success.
  const onScanSuccess = (result) => {
    setScannedData(result?.data);
  };

  // handes failure
  const onScanFail = (err) => {
    // do nothing for now
  };

  useEffect(() => {
    if (scannedData) {
      navigate(`/check-in/${scannedData}`);
    }

    return () => {
      scanner?.current?.stop();
      scanner.current = null;
      setScannedData("");
    };
  }, [scannedData]);

  //turns on the scanner when the user clicks on the scan button
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const isScanning = queryParams.get("scanner");
    setTurnOnScanner(isScanning === "true");

    if (isScanning === "true") {
      handleScan();
    }

    // Clean up on unmount.
    // This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (videoEl?.current) {
        scanner?.current?.stop();
        scanner.current = null;
      }
    };
  }, [location.search]);

  // If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  const handleScan = () => {
    if (videoEl?.current && !scanner.current) {
      // Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // A custom div which will pair with "highlightScanRegion" option above. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // Start QR Scanner
      scanner.current
        .start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }
  };

  return (
    <>
      <div className={`qr-reader ${turnOnScanner ? "d-block" : "d-none"}`}>
        <Col xs={12}>
          <div className={classes.topBarContainer}>
            <div
              className={classes.backButtonContainer}
              onClick={() => navigate("/scan-tickets")}
            >
              <span
                className={`material-symbols-outlined ${classes.backButtonIcon}`}
              >
                video_camera_front_off
              </span>
            </div>
          </div>
        </Col>
        <video ref={videoEl}></video>
        <div ref={qrBoxEl} className="qr-box">
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={256}
            height={256}
            className="qr-frame"
          />
        </div>
      </div>
      <Row className={`${turnOnScanner ? "d-none" : "d-flex"}`}>
        <Col xs={12}>
          <div className={classes.scanButtonContainerParent}>
            <div
              className={classes.scanIconContainer}
              onClick={() => navigate("/scan-tickets/?scanner=true")}
            >
              <span className={`material-symbols-outlined ${classes.scanIcon}`}>
                qr_code_scanner
              </span>
            </div>
            <p className={classes.startScanningText}>start scanning</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default QrReader;
