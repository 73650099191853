import { MuiTelInput } from "mui-tel-input";
import classes from "./CheckoutTelInput.module.css";
import "./CheckoutTelInput.css";
import FormikInputField from "./FormikInputField";

const CheckoutTelInput = ({ phoneNumber, setPhoneNumber, setFieldValue }) => {
  const handleChange = (newValue, info) => {
    const country =
      info.countryCode === "LK" ? "Sri Lanka" : "United Arab Emirates";

    setPhoneNumber(newValue);
    setFieldValue("phone", newValue.replace(/\s+/g, ""));
    setFieldValue("country", country);
  };

  return (
    <div className={classes.phoneNumberInputContainer}>
      <FormikInputField
        label="Phone"
        fieldName="phone"
        inputType="hidden"
        className={classes.hiddenFormInput}
        required
      />
      <MuiTelInput
        value={phoneNumber}
        onChange={handleChange}
        className={classes.phoneNumberContainer}
        defaultCountry={"LK"}
        preferredCountries={["LK"]}
        onlyCountries={["LK"]}
        forceCallingCode
        focusOnSelectCountry
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              backgroundColor: "#ffffff",
              border: "1px solid rgba(56, 56, 56, 0.4);",
            },
          },
        }}
      />
    </div>
  );
};

export default CheckoutTelInput;
