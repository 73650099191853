import { Container, Row, Col } from "react-bootstrap";
import classes from "./PrivacyPolicy.module.css";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

const RefundPolicy = () => {
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Refund Policy`);

  return (
    <section className={classes.pageContainer}>
      <Container className="customContainer">
        <Row>
          <Col xs={12}>
            <div className={classes.pageTitleContainer}>
              <h1 className={classes.pageTitle}>Refund Policy</h1>
              <div className={classes.pageTitleUnderline}></div>
            </div>
            <p className={classes.content}>
              <span style={{ fontWeight: "500" }}>
                Last Updated July 20, 2024
              </span>
            </p>
            <p className={classes.content}>
              This Refund Policy outlines the conditions under which refunds
              will be granted for tickets purchased through{" "}
              <span className={classes.siteName}>eventgooze.com</span>. By
              purchasing tickets on our website, you agree to the terms of this
              policy.
            </p>
            <h4 className={classes.subTitle}>General Refund Conditions</h4>
            <p className={classes.content}>
              Refunds will be issued only in the following circumstances:
            </p>
            <ul className={classes.list}>
              <li className={classes.listItem}>If an event is canceled.</li>
              <li className={classes.listItem}>
                If an event is rescheduled and you are unable to attend the new
                date.
              </li>
              <li className={classes.listItem}>
                If tickets are canceled by us due to misuse, fraud, or
                non-compliance with our terms and conditions.
              </li>
            </ul>
            <h4 className={classes.subTitle}>
              Refunds will not be issued for any other reasons, including but
              not limited to:
            </h4>
            <ul className={classes.list}>
              <li className={classes.listItem}>Change of mind.</li>
              <li className={classes.listItem}>
                Personal circumstances preventing attendance.
              </li>
            </ul>
            <h4 className={classes.subTitle}>
              Event Cancellations and Rescheduling
            </h4>
            <p className={classes.content}>
              <strong style={{ fontWeight: "600" }}>
                Event Cancellations:{" "}
              </strong>
              If an event is canceled, you will receive a full refund of the
              ticket price.
            </p>
            <p className={classes.content}>
              <strong style={{ fontWeight: "600" }}>
                Event Rescheduling:{" "}
              </strong>
              If an event is rescheduled, your ticket will be valid for the new
              date. If you cannot attend the rescheduled event, you may request
              a refund within 14 days of the announcement of the new date.
            </p>
            <h1 className={classes.subTitle}>Ticket Cancellations by Us</h1>
            <p className={classes.content}>
              a. We reserve the right to cancel tickets if we detect that
              tickets have been purchased using false information, have been
              misused through promotions, or have been bought for purposes other
              than personal use.
            </p>
            <p className={classes.content}>
              b. In such cases, a refund will be issued for the amount paid for
              the tickets.
            </p>
            <h1 className={classes.subTitle}>Refund Process</h1>
            <p className={classes.content}>
              a. To request a refund, please contact our customer service team
              at{" "}
              <a
                href="mailto:hello@eventgooze.com"
                style={{ textDecoration: "none" }}
                className={classes.linkText}
              >
                <span className={classes.siteName}>hello@eventgooze.com</span>
              </a>{" "}
              with your order details and reason for the refund request.
            </p>
            <p className={classes.content}>
              b. Once your refund request is received, we will review it and
              notify you of the approval or rejection of your refund within 7
              business days.
            </p>
            <p className={classes.content}>
              c. If your refund is approved, it will be processed, and a credit
              will automatically be applied to your original method of payment
              within 10 business days.
            </p>
            <h1 className={classes.subTitle}>Non-Refundable Fees</h1>
            <p className={classes.content}>
              Service fees, handling fees, and any other fees are non-refundable
              unless specified otherwise.
            </p>
            <h1 className={classes.subTitle}>Contact Us</h1>
            <p className={classes.content}>
              If you have any questions or concerns about our refund policy,
              please contact us using the information provided on our website.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RefundPolicy;
