import { useEffect, useState } from "react";
import changeDateFormat from "../utils/changeDateFormat";
import TicketMetaDataContainer from "./TicketMetaDataContainer";
import classes from "./TicketTimeVenueContainer.module.css";

const TicketTimeVenueContainer = ({
  startDateAndTime,
  endDateAndTime,
  location,
}) => {
  const [isSameDate, setIsSameDate] = useState(false);

  useEffect(() => {
    if (
      startDateAndTime.toISOString().split("T")[0] ===
      endDateAndTime.toISOString().split("T")[0]
    ) {
      setIsSameDate(true);
    }
  }, [startDateAndTime, endDateAndTime]);

  return (
    <div className={`${classes.ticketTimeVenueContainer}`}>
      <TicketMetaDataContainer
        label="date"
        icon="calendar_month"
        data={
          isSameDate
            ? changeDateFormat(startDateAndTime)
            : `${changeDateFormat(startDateAndTime)} - ${changeDateFormat(
                endDateAndTime
              )}`
        }
      />
      <div className={classes.eventDetailsDivider}></div>
      <TicketMetaDataContainer
        label="time"
        icon="schedule"
        data={`${startDateAndTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })} - ${endDateAndTime.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`}
      />
      <div className={classes.eventDetailsDivider}></div>
      <TicketMetaDataContainer label="venue" icon="home_pin" data={location} />
      <div className={`d-none ${classes.eventDetailsDivider}`}></div>
    </div>
  );
};

export default TicketTimeVenueContainer;
