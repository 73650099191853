import { useNavigate } from "react-router-dom";
import classes from "./NavbarBusinessLogo.module.css";
import siteLogo from "../images/business-logo.png";

const NavbarBusinessLogo = ({ className }) => {
  const navigate = useNavigate();

  return (
    <img
      src={siteLogo}
      alt="site logo"
      className={`${classes.businessLogo} ${className}`}
      onClick={() => navigate("/")}
    />
  );
};

export default NavbarBusinessLogo;
