import { Container, Row, Col } from "react-bootstrap";
import classes from "./PrivacyPolicy.module.css";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

const PrivacyPolicy = () => {
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Privacy Policy`);

  return (
    <section className={classes.pageContainer}>
      <Container className="customContainer">
        <Row>
          <Col xs={12}>
            <div className={classes.pageTitleContainer}>
              <h1 className={classes.pageTitle}>Privacy Policy</h1>
              <div className={classes.pageTitleUnderline}></div>
            </div>
            <p className={classes.content}>
              <span style={{ fontWeight: "500" }}>
                Last Updated July 20, 2024
              </span>
            </p>
            <p className={classes.content}>
              At <span className={classes.siteName}>eventgooze.com</span>, we
              are committed to protecting the privacy and security of our
              customers' personal information. This Privacy Policy outlines how
              we collect, use, and safeguard your information when you visit or
              make a purchase on our website. By using our website, you consent
              to the practices described in this policy.
            </p>
            <h4 className={classes.subTitle}>Information We Collect</h4>
            <p className={classes.content}>
              When you visit our website, we may collect certain information
              about you, including:
            </p>
            <ul className={classes.list}>
              <li>
                Personal identification information (such as your name, email
                address, and phone number) provided voluntarily by you during
                the registration or checkout process.
              </li>
              <li>
                Payment and billing information necessary to process your
                orders, including credit card details, which are securely
                handled by trusted third-party payment processors.
              </li>
              <li>Your payment information</li>
              <li>
                Browsing information, such as your IP address, browser type, and
                device information, collected automatically using cookies and
                similar technologies.
              </li>
            </ul>
            <h1 className={classes.subTitle}>Use of Information</h1>
            <p className={classes.content}>
              We may use the collected information for the following purposes:
            </p>
            <ul className={classes.list}>
              <li>To process and fulfill your orders.</li>
              <li>
                To communicate with you regarding your purchases, provide
                customer support, and respond to inquiries or requests.
              </li>
              <li>
                To improve our website and user experience based on your
                feedback and browsing patterns.
              </li>
              <li>
                To detect and prevent fraud, unauthorized activities, and abuse
                of our website.
              </li>
            </ul>
            <h1 className={classes.subTitle}>Information Sharing</h1>
            <p className={classes.content}>
              We respect your privacy and do not sell, trade, or otherwise
              transfer your personal information to third parties without your
              consent, except in the following circumstances:
            </p>
            <ul className={classes.list}>
              <li>
                Trusted service providers: We may share your information with
                third-party service providers who assist us in operating our
                website and processing payments. These providers are
                contractually obligated to handle your data securely and
                confidentially.
              </li>
              <li>
                Legal requirements: We may disclose your information if required
                to do so by law or in response to valid legal requests or
                orders.
              </li>
            </ul>
            <h1 className={classes.subTitle}>Data Security</h1>
            <p className={classes.content}>
              We implement industry-standard security measures to protect your
              personal information from unauthorized access, alteration,
              disclosure, or destruction. However, please be aware that no
              method of transmission over the internet or electronic storage is
              100% secure, and we cannot guarantee absolute security.
            </p>
            <h1 className={classes.subTitle}>
              Cookies and Tracking Technologies
            </h1>
            <p className={classes.content}>
              We use cookies and similar technologies to enhance your browsing
              experience, analyze website traffic, and gather information about
              your preferences and interactions with our website. You have the
              option to disable cookies through your browser settings, but this
              may limit certain features and functionality of our website.
            </p>
            <h1 className={classes.subTitle}>Changes to the Privacy Policy</h1>
            <p className={classes.content}>
              We reserve the right to update or modify this Privacy Policy at
              any time. Any changes will be posted on this page with a revised
              "last updated" date. We encourage you to review this Privacy
              Policy periodically to stay informed about how we collect, use,
              and protect your information.
            </p>
            <h1 className={classes.subTitle}>Contact Us</h1>
            <p className={classes.content}>
              If you have any questions, concerns, or requests regarding our
              Privacy Policy or the handling of your personal information,
              please contact us using the information provided on our website.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
