import classes from "./FormikInputField.module.css";

import { Field, ErrorMessage } from "formik";

const FormikInputField = ({
  label,
  fieldName,
  inputType,
  required,
  rows = null,
  cols = null,
  className,
  disabled = false,
}) => {
  return (
    <div className={`${classes.componentDiv} ${className}`}>
      <label htmlFor={fieldName} className={classes.label}>
        {label}
        {required && <span className={classes.requiredSign}>*</span>}
        <span className={`${classes.errorMessage} d-lg-inline d-none`}>
          <ErrorMessage name={fieldName} />
        </span>
      </label>
      {inputType == "textarea" ? (
        <Field
          component="textarea"
          id={fieldName}
          name={fieldName}
          className={classes.inputBox}
          disabled={disabled}
          autoComplete="off"
          rows={rows}
          cols={cols}
        />
      ) : (
        <Field
          type={inputType}
          id={fieldName}
          name={fieldName}
          className={classes.inputBox}
          disabled={disabled}
          autoComplete="off"
        />
      )}
      <span className={`${classes.errorMessage} d-lg-none d-block`}>
        <ErrorMessage name={fieldName} />
      </span>
    </div>
  );
};

export default FormikInputField;
