import { Col } from "react-bootstrap";
import classes from "./AllEventsSingleEventComponent.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const AllEventsSingleEventComponent = ({
  eventId,
  eventThumbnail,
  eventTitle,
  eventSlug,
  eventStartDate,
}) => {
  const navigate = useNavigate();
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (eventStartDate) {
      const date = new Date(eventStartDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      setDate(formattedDate);
    }
  }, [eventStartDate]);

  return (
    <div
      className={classes.singleEventContainer}
      onClick={() => navigate(`/events/${eventId}/${eventSlug}`)}
    >
      <div
        className={classes.eventThumbnailContainer}
        style={{ backgroundImage: `url(${eventThumbnail})` }}
      ></div>
      <div className={classes.eventDetailsContainer}>
        <h6 className={classes.eventName}>{eventTitle}</h6>
        <p className={classes.eventDate}>{date}</p>
      </div>
    </div>
  );
};

export default AllEventsSingleEventComponent;
