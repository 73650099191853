import classes from "./HomepageBenefitItem.module.css";
import { Col, Row } from "react-bootstrap";

const HomepageBenefitItem = ({ number, title, content }) => {
  return (
    <Col sm={6} xs={12} className={classes.benefitItem}>
      <Row>
        <Col xs="auto">
          <div className={classes.benefitItemNumberContainer}>
            <p className={classes.benefitItemNumber}>{number}</p>
          </div>
        </Col>
        <Col>
          <h4 className={classes.benefitItemTitle}>{title}</h4>
          <p className={classes.benefitItemContent}>{content}</p>
        </Col>
      </Row>
    </Col>
  );
};

export default HomepageBenefitItem;
