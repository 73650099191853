const checkoutAction = (formData, orderId, sendRequest) => {
  const data = formData;

  const user_data = JSON.parse(data.data);

  const resolved = (response) => {
    return { status: "ok", intent: "initiate-payment", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "initiate-payment", error };
  };

  return sendRequest(
    "patch",
    `/order/${orderId}/checkout/`,
    user_data,
    resolved,
    rejected
  );
};

const addPromoAction = (formData, orderId, sendRequest) => {
  const data = formData;

  const resolved = (response) => {
    return { status: "ok", intent: "add-promo", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "add-promo", error };
  };

  return sendRequest(
    "patch",
    `/order/${orderId}/add-promo/`,
    JSON.parse(data.data),
    resolved,
    rejected
  );
};

const removePromoAction = (orderId, sendRequest) => {
  const data = null;

  const resolved = (response) => {
    return { status: "ok", intent: "remove-promo", response };
  };

  const rejected = (error) => {
    return { status: "error", intent: "remove-promo", error };
  };

  return sendRequest(
    "patch",
    `/order/${orderId}/remove-promo/`,
    data,
    resolved,
    rejected
  );
};

// This method is responsible for returning the appropriate action(method which call the necessary API endpoint)
// based on the intent of the form data
// All the actions are defined above in this file
export const CheckoutPageActions =
  (sendRequest) =>
  async ({ request, params: { order_id: orderId } }) => {
    const formData = await request.formData();
    let intent = formData.get("intent");
    const data = Object.fromEntries(formData);

    if (intent === "initiate-payment") {
      return checkoutAction(data, orderId, sendRequest);
    }

    if (intent === "add-promo") {
      return addPromoAction(data, orderId, sendRequest);
    }

    if (intent === "remove-promo") {
      return removePromoAction(orderId, sendRequest);
    }

    throw { message: "Invalid intent", status: 400 };
  };
