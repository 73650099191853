import React, { useEffect, useState } from "react";
import { useSubmit, useNavigation, useActionData } from "react-router-dom";
import classes from "./TicketScanPage.module.css";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import FormikInputField from "./FormikInputField";
import { BeatLoader } from "react-spinners";
import displayToastErrors from "../utils/displayToastErrors";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";
import QrReader from "./QrReader";

export default function TicketScanPage() {
  const navigation = useNavigation();
  const submit = useSubmit();
  const actionData = useActionData();
  const [isAuthenticated, setIsAuthenticated] = useState(
    () => JSON.parse(localStorage.getItem("isLoggedIn")) || false
  );
  const { setDoucmentTitle } = useDocumentTitle(`Eventgooze | Scan Tickets`);

  const initialValues = {
    username: "",
    password: "",
  };

  //form validation rules
  const validationSchema = Yup.object({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const onSubmit = (values) => {
    submit(
      {
        email: values.username,
        password: values.password,
      },
      {
        method: "post",
        action: `/scan-tickets`,
      }
    );
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
        localStorage.setItem(
          "user",
          JSON.stringify({
            accessToken: actionData?.response?.data.data.access_token,
          })
        );
        setIsAuthenticated(true);
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message,
          "Faild to login!"
        );
      }
    }
  }, [actionData]);

  return (
    <section className={classes.scannerPage}>
      {isAuthenticated ? (
        <Container className={classes.customContainer}>
          <div className={classes.scannerContainer}>
            <Row>
              <Col xs={12}>
                <QrReader />
              </Col>
            </Row>
          </div>
        </Container>
      ) : (
        <Container className={classes.customContainer}>
          <Row>
            <Col xs={12}>
              <div className={classes.loginFormContainer}>
                <div style={{ margin: "auto" }} className="w-100">
                  <h4 className={classes.loginTitle}>Login</h4>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    <Form>
                      <div className={classes.formRow}>
                        <FormikInputField
                          label="Username"
                          fieldName="username"
                          inputType="text"
                          required
                        />
                      </div>
                      <div className={classes.formRow}>
                        <FormikInputField
                          label="Password"
                          fieldName="password"
                          inputType="password"
                          required
                        />
                      </div>
                      <PrimaryActionButton
                        className={classes.loginButton}
                        type="submit"
                        buttonText={
                          navigation.state === "idle" ? (
                            "login"
                          ) : (
                            <BeatLoader loading size={10} />
                          )
                        }
                      />
                    </Form>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
}

export const LoginAction =
  (sendRequest) =>
  async ({ request }) => {
    const formData = await request.formData();
    const data = Object.fromEntries(formData);

    return sendRequest("post", `/login/`, data);
  };
