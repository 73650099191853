const changeDateFormat = (date) => {
  const dateObj = new Date(date);
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return `${dateObj.getDate()} ${
    monthNames[dateObj.getMonth()]
  }, ${dateObj.getFullYear()}`;
};

export default changeDateFormat;
