import { Container, Row, Col } from "react-bootstrap";
import classes from "./PrivacyPolicy.module.css";
import useDocumentTitle from "../custom-hooks/useDocumentTitle";

const TermsAndConditions = () => {
  const { setDoucmentTitle } = useDocumentTitle(
    `Eventgooze | Terms & Conditions`
  );

  return (
    <section className={classes.pageContainer}>
      <Container className="customContainer">
        <Row>
          <Col xs={12}>
            <div className={classes.pageTitleContainer}>
              <h1 className={classes.pageTitle}>Terms And Conditions</h1>
              <div className={classes.pageTitleUnderline}></div>
            </div>
            <p className={classes.content}>
              <span style={{ fontWeight: "500" }}>
                Last Updated July 20, 2024
              </span>
            </p>
            <p className={classes.content}>
              Welcome to{" "}
              <span className={classes.siteName}>eventgooze.com</span>. By
              accessing or using our website, you agree to comply with and be
              bound by the following terms and conditions. Please read them
              carefully. If you do not agree to these terms, you should not use
              our website.
            </p>
            <h4 className={classes.subTitle}>Definitions</h4>
            <ul className={classes.list}>
              <li className={classes.listItme}>
                <strong style={{ fontWeight: "600" }}>Website: </strong>
                <span className={classes.siteName}>eventgooze.com</span>
              </li>
              <li className={classes.listItme}>
                <strong style={{ fontWeight: "600" }}>We/Us/Our: </strong>The
                owners and operators of the website.
              </li>
              <li className={classes.listItme}>
                <strong style={{ fontWeight: "600" }}>User/You: </strong>
                Any person who accesses or uses the website.
              </li>
              <li className={classes.listItme}>
                <strong style={{ fontWeight: "600" }}>Event: </strong>
                Any event for which tickets are sold through the website.
              </li>
              <li className={classes.listItme}>
                <strong style={{ fontWeight: "600" }}>Ticket: </strong>A valid
                entry pass purchased through the website.
              </li>
            </ul>
            <h4 className={classes.subTitle}>Use of the Website</h4>
            <p className={classes.content}>
              a. You must be at least 18 years old to use our website.
            </p>
            <p className={classes.content}>
              b. You agree to provide accurate, current, and complete
              information during the registration and purchase process.
            </p>
            <p className={classes.content}>
              c. You agree to use the website for lawful purposes only
            </p>
            <h1 className={classes.subTitle}>Ticket Purchase and Payment</h1>
            <p className={classes.content}>
              a. All ticket prices are listed in Sri Lankan Rupees and are
              subject to change without notice.
            </p>
            <p className={classes.content}>
              b. Tickets can be purchased using the payment methods provided on
              the website.
            </p>
            <p className={classes.content}>
              c. Once a ticket is purchased, it cannot be transferred, resold,
              or exchanged unless otherwise specified.
            </p>
            <p className={classes.content}>
              d. You will receive an email confirmation and a PDF ticket upon
              successful payment.
            </p>
            <p className={classes.content}>
              e. If we detect that tickets have been purchased using false
              information, have been misused through promotions, or have been
              bought for purposes other than personal use, we reserve the right
              to cancel your tickets at any time and issue a refund for the
              amount paid, if applicable.
            </p>
            <h1 className={classes.subTitle}>
              Event Changes and Cancellations
            </h1>
            <p className={classes.content}>
              a. We reserve the right to cancel or reschedule events at our
              discretion.
            </p>
            <p className={classes.content}>
              b. If an event is canceled, you will receive a full refund of the
              ticket price.
            </p>
            <p className={classes.content}>
              c. If an event is rescheduled, your ticket will be valid for the
              new date. If you cannot attend the rescheduled event, you may
              request a refund.
            </p>
            <h1 className={classes.subTitle}>Limitation of Liability</h1>
            <p className={classes.content}>
              a. We are not responsible for any loss or damage resulting from
              your use of the website or attendance at any event.
            </p>
            <p className={classes.content}>
              b. Our total liability to you for any claim arising from your use
              of the website or attendance at any event is limited to the amount
              paid for the ticket.
            </p>
            <h1 className={classes.subTitle}>Refunds Policy</h1>
            <p className={classes.content}>
              a. Our Refund Policy governs the process and conditions for
              returning products and seeking refunds. Please refer to the refund
              policy provided on our website for more information.
            </p>
            <h1 className={classes.subTitle}>Privacy</h1>
            <p className={classes.content}>
              a. We are committed to protecting your privacy. Please refer to
              our Privacy Policy for information on how we collect, use, and
              protect your personal data.
            </p>
            <h1 className={classes.subTitle}>Governing Law</h1>
            <p className={classes.content}>
              a. These terms and conditions are governed by and construed in
              accordance with the laws of Democratic Socialist Republic of Sri
              Lanka
            </p>
            <h1 className={classes.subTitle}>
              Duplication of tickets (False ticket generation)
            </h1>
            <p className={classes.content}>
              It is your responsibility to ensure that you carry the original
              confirmation receipt to the event with you.
            </p>
            <h1 className={classes.subTitle}>
              Changes to Terms and Conditions
            </h1>
            <p className={classes.content}>
              a. We reserve the right to update or modify these terms and
              conditions at any time. Any changes will be effective immediately
              upon posting on the website.
            </p>
            <h1 className={classes.subTitle}>Contact Us</h1>
            <p className={classes.content}>
              If you have any questions or concerns about these terms and
              conditions, please contact us using the information provided on
              our website.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TermsAndConditions;
