import classes from "./PricingValueItem.module.css";
import { Row, Col } from "react-bootstrap";

const PricingValueItem = ({ content }) => {
  return (
    <div className={classes.valueItemContainer}>
      <Row>
        <Col xs="auto">
          <div className={classes.valuePointIndicator}></div>
        </Col>
        <Col className={classes.valueItemTextContainer}>
          <p className={classes.valueItemText}>{content}</p>
        </Col>
      </Row>
    </div>
  );
};

export default PricingValueItem;
