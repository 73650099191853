import { useEffect, useState } from "react";
import AddPromoCodeModal from "./AddPromoCodeModal";
import classes from "./PromoCode.module.css";
import { Row, Col } from "react-bootstrap";
import { useParams, useSubmit, useActionData } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import displayToastErrors from "../utils/displayToastErrors";

const PromoCode = ({ promoCodeApplied }) => {
  const { order_id: orderId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const actionData = useActionData();
  const submit = useSubmit();
  const [promoCodeModalOpen, setPromoCodeModalOpen] = useState(false);

  const removePromoCode = () => {
    setIsLoading(true);

    submit(
      { intent: "remove-promo", data: null },
      { method: "post", action: `/checkout/${orderId}` }
    );
  };

  useEffect(() => {
    if (actionData && actionData.intent === "remove-promo") {
      if (actionData.status === "ok") {
        toast.success("Promo code removed successfully");
      } else {
        displayToastErrors(
          actionData?.error?.response?.data?.message ||
            "Faild to add promo code"
        );
      }

      setIsLoading(false);
    }
  }, [actionData]);

  return (
    <Row>
      <Col>
        <div className={classes.promoButtonContainer}>
          {promoCodeApplied ? (
            <p className={classes.havePromocodeText} onClick={removePromoCode}>
              {isLoading ? (
                <BeatLoader loading size={8} color="#07004d" />
              ) : (
                "Remove promo code"
              )}
            </p>
          ) : (
            <p
              className={classes.havePromocodeText}
              onClick={() => setPromoCodeModalOpen(true)}
            >
              Have a promo code ?
            </p>
          )}
        </div>
      </Col>
      <AddPromoCodeModal
        show={promoCodeModalOpen}
        onHide={() => setPromoCodeModalOpen(false)}
      />
    </Row>
  );
};

export default PromoCode;
