import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classes from "./ContactUsModal.module.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import PrimaryActionButton from "./PrimaryActionButton";
import useHttp from "../custom-hooks/useHttp";
import { BeatLoader } from "react-spinners";
import FormikInputField from "./FormikInputField";
import CheckoutTelInput from "./CheckoutTelInput";
import { toast } from "react-toastify";

const ContactUsModal = ({ contactUsDefaultMessage, ...props }) => {
  const { sendRequestAndTrackProgress, isLoading, actionData } = useHttp();
  const [phoneNumber, setPhoneNumber] = useState("");
  // initial values passed to the form
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    country: "",
  });

  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      message: contactUsDefaultMessage,
    }));
  }, [contactUsDefaultMessage]);

  //form validation rules
  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required!"),
    email: Yup.string()
      .email("Invalid email format")
      .required("This field is required!"),
    phone: Yup.string()
      .test("is-valid-phone", "Invalid phone number", (value) => {
        if (!value) return false; // Check if value is not null or undefined
        if (value.startsWith("+94") && value.length === 12) {
          return true; // +94 should have 9 digits after the country code, total length 12
        } else if (value.startsWith("+971") && value.length === 13) {
          return true; // +971 should have 9 digits after the country code, total length 13
        }
        return false; // Fails validation if none of the above conditions are met
      })
      .required("This field is required"),
    message: Yup.string()
      .required("This field is required!")
      .min(10, "Message must be at least 10 characters")
      .max(1000, "Message must be less than 1000 characters"),
  });

  const onSubmit = (values) => {
    const message = {
      name: values.name,
      email: values.email,
      phone: phoneNumber,
      message: values.message,
    };

    sendRequestAndTrackProgress("post", "/contact/", message);
  };

  useEffect(() => {
    if (actionData) {
      if (actionData.status === "ok") {
        toast.success(actionData.response.data.message);
        props.onHide();
      } else if (actionData.status === "error") {
        toast.error(
          actionData.error.response.data.message ||
            "Failed to send the message!"
        );
      }
    }
  }, [actionData]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classes.modalContainer}
    >
      <Modal.Body className={classes.modalBody}>
        <span
          className={`material-symbols-outlined ${classes.modalCloseButton}`}
          onClick={() => props.onHide()}
        >
          close
        </span>
        <Row>
          <Col>
            <h4 className={classes.modalTitle}>contact us</h4>
          </Col>
        </Row>
        <div>
          <p className={classes.modalMessage}>
            Send us a message and we'll reach you within 60 minutes.
          </p>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ setFieldValue }) => (
              <Form>
                <div className={classes.formRow}>
                  <FormikInputField
                    label="Name"
                    fieldName="name"
                    inputType="text"
                    required
                  />
                </div>
                <div className={classes.formRow}>
                  <FormikInputField
                    label="Email"
                    fieldName="email"
                    inputType="email"
                    required
                  />
                </div>
                <div className={classes.formRow}>
                  <CheckoutTelInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <div className={classes.formRow}>
                  <FormikInputField
                    label="Message"
                    fieldName="message"
                    inputType="textarea"
                    rows="4"
                    cols="50"
                    required
                  />
                </div>
                <PrimaryActionButton
                  className={classes.sendMessageButton}
                  type="submit"
                  buttonText={
                    isLoading ? (
                      <BeatLoader loading size={10} />
                    ) : (
                      "Send Message"
                    )
                  }
                />
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModal;
