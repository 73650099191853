import classes from "./TicketCountComponenet.module.css";

const TicketCountComponenet = ({
  packageId,
  ticketCount,
  setTicketCount,
  setTotal,
  unitPrice,
}) => {
  const decreaseTicketCount = () => {
    if (ticketCount > 0) {
      setTicketCount((prevCount) => prevCount - 1);
      setTotal((prevTotal) => prevTotal - unitPrice);
    }
  };

  const increaseTicketCount = () => {
    setTicketCount((prevCount) => prevCount + 1);
    setTotal((prevTotal) => prevTotal + unitPrice);
  };

  return (
    <div className={`d-flex`}>
      <div className={classes.counterButton} onClick={decreaseTicketCount}>
        <span className={`material-symbols-outlined ${classes.buttonContent}`}>
          remove
        </span>
      </div>
      <div className={classes.countContainer}>
        <input
          type="hidden"
          value={ticketCount}
          data-package-id={packageId}
          data-unit-price={unitPrice}
        />
        <span>
          {ticketCount === 0
            ? "00"
            : ticketCount < 10
            ? `0${ticketCount}`
            : ticketCount}
        </span>
      </div>
      <div className={classes.counterButton} onClick={increaseTicketCount}>
        <span className={`material-symbols-outlined ${classes.buttonContent}`}>
          add
        </span>
      </div>
    </div>
  );
};

export default TicketCountComponenet;
