import { useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import { useEffect } from "react";
import ContactUsModal from "./ContactUsModal";
import { contactContext } from "../App";

const BaseLayout = () => {
  const { contactModalShow, setContactModalShow, contactUsDefaultMessage } =
    useContext(contactContext);
  const { pathname } = useLocation();

  // automatically scrolls the page to the top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <div>
      <>
        <NavigationBar />
        <Outlet />
        <ContactUsModal
          show={contactModalShow}
          onHide={() => setContactModalShow(false)}
          contactUsDefaultMessage={contactUsDefaultMessage}
        />
        <Footer />
      </>
    </div>
  );
};

export default BaseLayout;
